import React, { useState, ReactElement, ReactNode } from 'react'
import classNames from 'classnames';
import injectSheet from 'react-jss'

const styles = {
  dropdownMenu: {
    position: 'relative',
    // height: '24px',
    // lineHeight: '24px',
  },
  dropdownMenuItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '0',
    position: 'absolute',
    opacity: '0',
    left: '0',
    top: '40px',
    color: '#999',
    backgroundColor: '#FFF',
    borderRadius: "4px",
    boxShadow: '0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    transition: 'opacity 200ms linear, width 200ms linear',

    "&.-is-hovering": {
      opacity: '1',
      width: '200px',
    }
  }
}

interface DropdownMenuProps {
    children: ReactElement,
    trigger: ReactElement,
    main: ReactElement
    classes?: any
}

const DropdownMenu = ({classes: c, children, main}: DropdownMenuProps) => {  
  const [menuCollapse, setMenuCollapse] = useState<boolean>(false)

  return (
    <div className={c.dropdownMenu} onMouseEnter={() => setMenuCollapse(true)} onMouseLeave={() => setMenuCollapse(false)}>
      { main }
      
      <div className={classNames(c.dropdownMenuItems, menuCollapse && '-is-hovering')}>{children}</div>
      
    </div>
  )
}


export default injectSheet(styles)(DropdownMenu);
