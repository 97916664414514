import * as React from 'react'
import injectSheet from 'react-jss'
import { Link } from 'gatsby'
import { FaTwitter, FaYoutube, FaEnvelope, FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import { Image } from "cloudinary-react"
import Boundaries from '../Boundaries/Boundaries'
import HubspotFormDialog from '../../components/Hubspot/Form'
import SocialBar from '../SocialBar';

const styles = {
  footer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: '#2E9FC3',
    padding: '24px 0',

    '@media (max-width: 1250px)': {
      padding: '24px 0 0 0',
    }
  },
  footerContent: {
    display: 'flex',
    padding: '24px 0',
    justifyContent: 'space-around',
    width: '100%',
    '@media (max-width: 1250px)': {
      flexDirection: 'column-reverse',
      marginBottom: '48px',

      "& > div:first-of-type": {
        marginTop: '48px',
      }
    }
  },
  footerContentLinks: {
    display: 'flex',
    width: '100%',
    '& > div': {
      width: '250px',
      marginRight: '48px',
      '& > .links': {
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        fontWeight: '400',
        fontSize: '0.9rem',
        lineHeight: '2.1rem',
        '& a': {
          color: '#FFFFFF',
          textDecoration: 'none',
          cursor: 'pointer',

          '&:hover': {
            color: 'rgba(255, 255, 255, 0.4)'
          },
        },
      },
    },
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
      justifyContent: 'initial',
      '& > div:first-of-type, & > div:nth-of-type(2)': {
        width: '50%',
        marginRight: '0',
      },
      '& > div:nth-of-type(3)': {
        width: '100%',
        marginRight: '0',
        marginTop: '24px',
      },
    },
  },
  footerContentHeader: {
    fontSize: '1.1rem',
    fontWeight: '700',
    color: 'rgba(255, 255, 255, 0.6)'
  },
  footerLeftWrapper: {
    flex: '0 0 50%',
    display: 'flex',
    justifyContent: 'center',

    '@media (max-width: 1250px)': {
      display: 'none'
    }
  },
  footerLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  footerBrand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px'
  },
  footerBrandLogo: {
    marginRight: '24px',
    height: '128px',

    "& > img": {
      borderRadius: '20px'
    }
  },
  footerBrandText: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '1.2rem'
  },
  footerBrandInfo: {
    color: '#fff',
    fontSize: '0.8rem',

    "& > div:first-of-type": {
      color: 'rgba(255, 255, 255, 0.6)',
      marginBottom: '16px'
    },

    "& > a": {
      textDecoration: 'none',
      color: '#fff',
    }
  },
  footerEnd: {
    height: '56px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'none',
    fontSize: '0.9rem',
    '& > div': {
      height: '100%',
    },
    '@media (max-width: 1250px)': {
      display: 'block'
    }
  },
  footerEndContent: {
    height: '100%',
    color: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *': {
      flex: '1',
      display: 'flex',
      
      "&:nth-child(1)": {
        justifyContent: 'flex-start'
      },
      "&:nth-child(2)": {
        justifyContent: 'center'
      },
      "&:nth-child(3)": {
        justifyContent: 'flex-end'
      }
    },
    '& > a': {
      color: '#FFFFFF',
      textDecoration: 'none',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
  },
  miniFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    "& > div:last-of-type": {
      marginBottom: "4px"
    }
  }
}

interface FooterProps {
  classes?: any
}

class Footer extends React.Component<FooterProps, {}> {
  state = {
    formId: '',
  }

  renderContactForm = () => {
    this.setState({ formId: '1e0d4ce1-2273-4dc5-8774-1a60518cf1f0' })
  }

  closeContactForm = () => {
    this.setState({ formId: '' })
  }

  renderMiniFooter = () => {
    const { classes: c } = this.props;
    return (
      <>
        <div className={c.miniFooter}>
          <SocialBar onMailClick={() => this.renderContactForm()} />
          <div>Copyright 2019 © HQ</div>
        </div>
        
      </>
    )
  }

  render() {
    const { classes: c } = this.props

    return (
      <div className={c.footer}>
        <Boundaries>
          <div className={c.footerContent}>
            <div className={c.footerLeftWrapper}>
              <div className={c.footerLeft}>
                <div className={c.footerBrand}>
                  <div className={c.footerBrandLogo}>
                    <Image cloudName="hq-app" secure publicId="logo.png" width="128" height="128" crop="scale" dpr="2.0" />
                  </div>
                  <div className={c.footerBrandText}>
                    <span>HQ.app</span>
                    <span>Work Centralized</span>
                  </div>
                </div>
                <div className={c.footerBrandInfo}>
                  { this.renderMiniFooter() }
                </div>
              </div>
            </div>
            <div className={c.footerContentLinks}>
              <div>
                <div className={c.footerContentHeader}>Product</div>
                <div className={'links'}>
                  <Link to="/use-cases">Ways to use HQ (Tour)</Link>
                  {/* <Link to="#">Customer Testimonial</Link> */}
                  <Link to="/apps">Supported Apps</Link>
                  {/* <Link to="#">Download</Link> */}
                  <Link to="/pricing-plans">Pricing</Link>
                  <a href={'https://www.hq.app/app'}>Log in</a>
                </div>
              </div>
              <div>
                <div className={c.footerContentHeader}>Resources</div>
                <div className={'links'}>
                  <Link to={'/blog'}>Blog</Link>
                  {/* <Link to={'#'}>Template Library</Link> */}
                  {/* <Link to={'#'}>Developer API Docs</Link> */}
                  <a href={'https://hq.app/help'}>Help Center</a>
                  {/* <Link to={'#'}>Books & Guides</Link> */}
                  {/* <Link to={'#'}>Webinars</Link> */}
                </div>
              </div>
              <div>
                <div className={c.footerContentHeader}>Company</div>
                <div className={'links'}>
                  <Link to={'/about'}>About Us</Link>
                  <Link to={'#'}>Contact Us</Link>
                  {/* <Link to={'#'}>Partner Program</Link> */}
                  {/* <Link to={'#'}>Jobs & Careers</Link> */}
                  {/* <Link to={'#'}>Press</Link> */}
                  <Link to={'/privacy-policy'}>Privacy Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </Boundaries>
        <div className={c.footerEnd}>
          <Boundaries>
            <div className={c.footerEndContent}>
              { this.renderMiniFooter() }
            </div>
          </Boundaries>
        </div>
        {!!this.state.formId && (
            <HubspotFormDialog portalId={'5161923'} formId={this.state.formId} handleClose={this.closeContactForm} />
          )}
      </div>
    )
  }
}

export default injectSheet(styles)(Footer)
