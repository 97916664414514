import * as React from 'react'
import injectSheet from 'react-jss'
import HQTheme from '../../theme/theme';
import { MdKeyboardArrowDown } from 'react-icons/md';

const styles = {
  headerItem: {
    height: '100%',
    display: 'flex',
    lineHeight: '24px',

    "& > div": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: '24px',
      fontWeight: '500',
      cursor: 'pointer',
      textDecoration: 'none',
      padding: '8px 6px',
      borderRadius: '4px',
      transition: 'background-color 300ms linear, color 300ms linear',
  
      "&:hover": {
      backgroundColor: HQTheme.palette.selection.light
      },
      "&:active": {
      backgroundColor: HQTheme.palette.selection.dark
      }
    },

    "& > div > a": {
      color: HQTheme.palette.secondary.main,
      textDecoration: 'none',
      marginRight: (props: any) => props.withArrow ? '8px' : '',
    },

    "& > div > svg": {
      color: '#999'
    }
  }
}

class HeaderItem extends React.Component<{classes?: any, children: any, withArrow?: boolean}, {}> {
  render() {
    const { classes: c, children, withArrow } = this.props;

    return (
      <div className={c.headerItem}>
        <div>
    { children } { withArrow && <MdKeyboardArrowDown size={24} /> }
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(HeaderItem);
