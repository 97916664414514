import * as React from 'react'
import injectSheet from 'react-jss'
import {PageNavigationProps as HeaderNavigationProps} from '../PageNavigation/PageNavigation'
import HeaderButtons from "./HeaderButtons";
import HeaderBrand from './HeaderBrand';
import { Link } from 'gatsby';
import DropdownMenu from '../DropdownMenu';
import DropdownMenuItem from '../DropdownMenu/DropdownMenuItem';
import HeaderItem from './HeaderItem';

const styles = {
  navigation: {
    flex: '1 100%',
    justifyContent: 'space-between',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center'
  },
  navigationItems: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '24px',
    justifyContent: 'space-between',

    "& > div": {
      display: 'flex',
      alignItems: 'center'
    }
  },
}

class HeaderNavigation extends React.Component<HeaderNavigationProps, {}> {
  render() {
    const { classes: c } = this.props;

    return (
      <div className={c.navigation}>
        <HeaderBrand />
        <div className={c.navigationItems}>
          <div>
            <DropdownMenu main={<HeaderItem withArrow><Link to="#">Why HQ</Link></HeaderItem>}>
              <DropdownMenuItem slug={'/use-cases'}>Ways to use HQ</DropdownMenuItem>
              <DropdownMenuItem slug={'/apps'}>Supported Apps</DropdownMenuItem>
              <DropdownMenuItem disabled slug={'#'}>Download</DropdownMenuItem>
              <DropdownMenuItem disabled slug={'#'}>Customer Testimonial</DropdownMenuItem>
            </DropdownMenu>
            <DropdownMenu main={<HeaderItem withArrow><Link to="#">Resources</Link></HeaderItem>}>
              <DropdownMenuItem slug={"/blog"}>Blog</DropdownMenuItem>
              <DropdownMenuItem disabled slug={"/templates"}>Template Library</DropdownMenuItem>
              <DropdownMenuItem disabled slug={"/api-documentation"}>Developer API Docs</DropdownMenuItem>
              <DropdownMenuItem externalLink slug={"https://hq.app/help"}>Help Center</DropdownMenuItem>
              <DropdownMenuItem disabled slug={"#"}>Books & Guides</DropdownMenuItem>
              <DropdownMenuItem disabled slug={"#"}>Webinars</DropdownMenuItem>
            </DropdownMenu>
            <HeaderItem><Link to={'/pricing-plans'}>Pricing</Link></HeaderItem>
          </div>
          <div className={c.headerButtons}>
            <HeaderButtons />
          </div>
        </div>  
      </div>
    )
  }
}

export default injectSheet(styles)(HeaderNavigation);
