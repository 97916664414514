import * as React from 'react'
import injectSheet from 'react-jss'
import {MdMenu} from 'react-icons/md'
import HQTheme from '../../theme/theme';
import HeaderBrand from './HeaderBrand';
import HeaderButtons from './HeaderButtons';

const menuItemsStyle = {
  marginRight: '12px',
  fontWeight: '500',
  //color: HQTheme.palette.secondary.main,
  cursor: 'pointer',
  textDecoration: 'none',
  padding: '8px 12px',
  borderRadius: '4px',
  transition: 'background-color 300ms linear, color 300ms linear',

  "&:hover": {
    backgroundColor: HQTheme.palette.selection.light
  },
  "&:active": {
    backgroundColor: HQTheme.palette.selection.dark
  }
}

const styles = {
  headerMobileMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    "@media (max-width: 500px)": {
      "& > div > a > div:last-of-type": {
        display: 'none'
      }
    }
  },
  headerMobileMenuIcon: {
    fontSize: '2rem',
    cursor: 'pointer',
    "@media (max-width: 1024px)": {
      borderRadius: '4px',
      padding: '8px',
      zIndex: '1',
      color: HQTheme.palette.text.secondaryContrast
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',

    "@media (max-width: 500px)": {
      "& > div > div:first-of-type": {
        display: 'none'
      }
    }
  }
}

interface HeaderMobileMenuProps {
  classes?: any,
  openMenu: () => void
}

class HeaderMobileMenu extends React.Component<HeaderMobileMenuProps, {}> {
  render() {
    const { classes: c, openMenu } = this.props;

    return (
      <div className={c.headerMobileMenu}>
        <HeaderBrand />
        <div className={c.wrapper}>
          <HeaderButtons />
          <MdMenu className={c.headerMobileMenuIcon} onClick={openMenu}/>
        </div>
      </div>
    )
  }
}

export default injectSheet(styles)(HeaderMobileMenu);
