import React, { ReactNode } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames';
import { Link } from 'gatsby';
import HQTheme from '../../theme/theme';
import { MdLink } from 'react-icons/md';

const styles = {
  dropdownMenuItem: {
    color: HQTheme.palette.primary.main,
    fontWeight: '500',
    backgroundColor: '#FFF',
    textDecoration: 'none',
    minWidth: '180px',
    padding: "12px",
    position: 'relative',

    '&:hover': {
        backgroundColor: HQTheme.palette.selection.light,
    },

    "& > svg": {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      right: '12px',
    }
  },
  isDisabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: '0.5'
  }
}

interface DropdownMenuItemProps {
    classes?: any,
    children: ReactNode,
    slug: string,
    disabled?: boolean,
    externalLink?: boolean
}

class DropdownMenuItem extends React.Component<DropdownMenuItemProps, {}> {
  render() {
    const { classes: c, children, slug, disabled, externalLink } = this.props;

    return (
      externalLink ? (
        <a href={slug} className={classNames(c.dropdownMenuItem, disabled && c.isDisabled)}>
          { children } <MdLink size={24} />
        </a>
      ) : (
        <Link to={slug} className={classNames(c.dropdownMenuItem, disabled && c.isDisabled)}>
          { children }
        </Link>
      )
    )
  }
}

export default injectSheet(styles)(DropdownMenuItem);
