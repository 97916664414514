import * as React from 'react'
import injectSheet from 'react-jss'
import HQTheme from '../../theme/theme'
import HeaderItem from './HeaderItem';

const styles = {
  headerButtons: {
  	zIndex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '40px',
		alignItems: 'center',
		"& > div > a": {
  			textDecoration: 'none',
			fontWeight: '500',
			marginRight: '16px',
		},
		"& > div:first-of-type > div": {
			backgroundColor: HQTheme.palette.selection.light,

			"& > a": {
				color: HQTheme.palette.text.secondaryContrast,
			},
			"&:hover": {
				backgroundColor: HQTheme.palette.primary.main,
				color: HQTheme.palette.text.primary,

				"& > a": {
					color: HQTheme.palette.text.primary,
				},
			},
			"&:active": {
				backgroundColor: HQTheme.palette.primary.light
			}
		},
		"& > div:last-of-type > div": {
			backgroundColor: HQTheme.palette.secondary.main,

			"& > a": {
				color: HQTheme.palette.text.primary,
			},
			"&:hover": {
				backgroundColor: HQTheme.palette.primary.main,
			},
			"&:active": {
				backgroundColor: HQTheme.palette.primary.light
			}
		}
  }
}

interface HeaderButtonProps {
	classes?: any
}

class HeaderButtons extends React.Component<HeaderButtonProps,{}> {
  render() {
  	const { classes: c } = this.props;
    return (
      <div className={c.headerButtons}>
		<HeaderItem><a href="https://hq.app/app" target={"_blank"}>Log in</a></HeaderItem>
		<HeaderItem><a href="https://hq.app/app" target={"_blank"}>Get started</a></HeaderItem>
      </div>
    )
  }
}

export default injectSheet(styles)(HeaderButtons);
