import * as React from 'react'
import injectSheet from 'react-jss'
import { FaTwitter, FaYoutube, FaEnvelope, FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'

const styles = {
  footerSocial: {
    display: 'flex',
    justifyContent: 'center',

    "& > a": {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      margin: '0 8px 4px 0',
      color: (props: any) => props.dark ? '#666' : '#fff',
      border: '1px solid #fff', 
      borderRadius: '50%',
      fontSize: '1.3em',

      "&:hover": {
        color: (props: any) => props.dark ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.6)',
        border: '1px solid rgba(255, 255, 255, 0.6)', 
        pointer: 'cursor'
      },

      '@media (max-width: 1250px)': {
        border: 'none', 

        "&:hover": {
          border: 'none', 
        },
      }
    }
  }
}

interface SocialBarProps {
  classes?: any,
  dark?: boolean,
  onMailClick: () => void
}

class SocialBar extends React.Component<SocialBarProps, {}> {
  render() {
    const { classes: c , onMailClick} = this.props

    return (
        <div className={c.footerSocial}>
            <a href={'https://www.facebook.com/HQAppBe/'} target={'_blank'}><FaFacebookF /></a>
            <a href={'https://www.linkedin.com/company/hqappbe/'} target={'_blank'}><FaLinkedinIn /></a>
            <a href={'https://www.instagram.com/hqappbe/'} target={'_blank'}><FaInstagram /></a>
            <a href={'https://twitter.com/hqappbe'} target={'_blank'}><FaTwitter /></a>
            <a href={'https://www.youtube.com/channel/UC9KJYHmjexQkzc_P2A0VArA/about?disable_polymer=1'} target={'_blank'}><FaYoutube /></a>
            { !! onMailClick && <a href={'#'} onClick={onMailClick}><FaEnvelope /></a> }
        </div>
    )
  }
}

export default injectSheet(styles)(SocialBar)
