import * as React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames';
import HeaderNavigation from './HeaderNavigation'
import HeaderMobileMenu from "./HeaderMobileMenu";
import HQTheme from '../../theme/theme'
import {CloudinaryContext} from 'cloudinary-react'
export const headerHeight = 80;

const styles = {
  headerWrapper: {
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    right: '0',
    left: '0',
    zIndex: '2',
    height: `${headerHeight}px`,
    boxShadow: '0 2px 4px 0 rgba(151, 145, 151, 0.1)',
    "&.-mobile": {
      display: 'none'
    },
    "@media (max-width: 800px)": {
      "&.-mobile": {
        display: 'flex'
      },
      "&.-desktop": {
        display: 'none'
      }
    }
  },
  header: {
    width: '75%',
    maxWidth: '1600px',
    margin: 'auto',
    "@media (max-width: 1024px)": {
      width: '95%',
    },
    "@media (max-width: 767px)": {
      width: '100%',
      margin: '0 24px',
    }
  },
}

interface HeaderProps {
  classes?: any,
  openMenu: () => void,
  hashLinks?: boolean
}

class Header extends React.Component<HeaderProps, {}> {
  createHeader(deviceClass, nav) {
    const { classes: c } = this.props;

    return (
      <div className={classNames(c.headerWrapper, deviceClass)}>
        <div id="header" className={c.header}>
          {nav}
        </div>
      </div>
    )
  }
  render() {
    const { classes: c, openMenu } = this.props;

    const mobileNav = <HeaderMobileMenu openMenu={openMenu} />;
    const desktopNav = <HeaderNavigation />;

    return (
        <div className={c.header}>
          {this.createHeader('-mobile', mobileNav)}
          {this.createHeader('-desktop', desktopNav)}
        </div>
      // <CloudinaryContext cloudName="hq-app" secure>

      // </CloudinaryContext>
    )
  }
}

export default injectSheet(styles)(Header);
