import * as React from 'react'
import injectSheet from 'react-jss'
import HeaderLogo from './HeaderLogo'
import HQTheme from '../../theme/theme'
import { Link } from 'gatsby';

const styles = {
  brand: {
    zIndex: '1',
    flex: '0 0 220px',
    fontFamily: 'Poppins, sans-serif',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',

    "@media (max-width: 500px)": {
      flex: '0 0 50px',
    },

    "& > a": {
      display: 'flex',
      textDecoration: 'none',

      "& > div": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '12px',

        "& > span": {
          lineHeight: '1.3rem',
          display: 'block',
          fontSize: '1rem',
          color: HQTheme.palette.text.secondaryContrast,
        },

        "& > span:first-of-type": {
          fontSize: '1.1rem',
          fontWeight: '500'
        },

        "& > span:last-of-type": {
          fontWeight: '300'
        }
    },
    
    }
  }
}

interface HeaderBrandProps {
  classes?: any
}

class HeaderBrand extends React.Component<HeaderBrandProps, {}> {
  render() {
    const { classes: c } = this.props;

    return (
      <div className={c.brand}>
        <Link to="/">
          <HeaderLogo />
          <div>
            <span>HQ.app</span>
            <span>Work Centralized</span>
          </div>
        </Link>
      </div>
    )
  }
}

export default injectSheet(styles)(HeaderBrand);
