import React from 'react';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import HQTheme from '../../theme/theme';
import { Link } from 'gatsby';

const styles = {
    simpleCollapseWrapper: {
        position: 'relative',
        maxHeight: '32px',
        overflow: 'hidden',
        transition: 'max-height 300ms ease-in-out',
        margin: '16px', 
        "&.-is-open": {
            maxHeight: '500px'
        }
    },
    simpleCollapse: {},
    simpleCollapseTitle: {
        height: '32px',
        lineHeight: '32px',
        fontSize: '24px',
        textAlign: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'block',
        color: HQTheme.palette.primary.main
    },
    openIcon: {
        
    },
    simpleCollapseContent: {
        margin: '16px 0 24px 0',
        display: 'flex',
        flexDirection: 'column',
    }
}

const SimpleCollapse = ({ classes: c, children, title, slug }: {classes?: any, children: any, title: string, slug?: string}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div
            className={classNames(c.simpleCollapseWrapper, isOpen ? '-is-open' : undefined)}
            onClick={toggle}
        >
            <div className={c.simpleCollapse}>
                { !! children ? (
                    <>
                    <div className={c.simpleCollapseTitle}>{title}</div>
                    <div className={c.simpleCollapseContent}>
                        { children }
                    </div>
                    </>
                ) : (
                    !! slug && <Link to={slug} className={c.simpleCollapseTitle}>{title}</Link>
                )}
            </div>
        </div>
    )
}

const itemStyles = {
    simpleCollapseItem: {
        textDecoration: 'none',
        fontSize: '1.4em',
        color: '#666',
        textAlign: 'center',
        height: '32px',
        lineHeight: '32px'
    }
}


const SimpleCollapseItemBase = ({ classes: c, title, slug, externalSlug }: { classes?: any, title: string, slug: string, externalSlug?: string}) => {
    return (
        !! externalSlug ? <a href={externalSlug} className={c.simpleCollapseItem}>{title}</a> : <Link to={slug} className={c.simpleCollapseItem}>{ title }</Link>
    )
}

export const SimpleCollapseItem = injectSheet(itemStyles)(SimpleCollapseItemBase)

export default injectSheet(styles)(SimpleCollapse)