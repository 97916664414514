import * as React from 'react'
import injectSheet from 'react-jss'
import { Image } from "cloudinary-react"

const styles = {
  logoContainer: {
    color: '#000000',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '56px',
    flex: '0 0 56px',
    height: '56px'
  },
  logo: {
    display: 'block',
    borderRadius: '12px'
  }
}

interface HeaderLogoProps {
  classes?: any
}

class HeaderLogo extends React.Component<HeaderLogoProps, {}> {
  render() {
    const { classes: c } = this.props;
    return (
        <div className={c.logoContainer}>
          <Image cloudName="hq-app" secure publicId="logo.png" className={c.logo} width="56" height="56" crop="scale" dpr="2.0" />
        </div>
    )
  }
}

export default injectSheet(styles)(HeaderLogo);
