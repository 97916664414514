import * as React from 'react'
import injectSheet from 'react-jss'
import PageNavigation from '../PageNavigation/PageNavigation'
import { MdClear } from 'react-icons/md'
import Img from "gatsby-image"
import SimpleCollapse, { SimpleCollapseItem } from '../SimpleCollapse';
import SocialBar from '../SocialBar';

const styles = {
  mobileMenu: {
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: '#FFFFFF',
    zIndex: '2',
    "& > nav": {
      height: 'calc(100% - 160px)'
    }
  },
  closeMenuIcon: {
    fontSize: '2rem',
    position: 'absolute',
    top: '32px',
    right: '24px',
    color: '#000',
    cursor: 'pointer'
  },
  startit: {
    position: 'absolute',
    left: '16px',
    bottom: '16px',
    "& > img": {
      width: '56px',
      filter: 'opacity(0.2) drop-shadow(0 0 0 white)'
    }
  },

  mobileMenuSection: {
    "& > div:first-of-type": {
      
    },
    "& > div:last-of-type": {
      
    }
  },
  mobileMenuSocial: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    padding: '0 0 8px 0'
  }
}

interface MobileMenuProps {
  classes?: any,
  closeMenu: () => void,
  image?: any,
  activePage?: any,
  sections?: any
  hashLinks?: any
}

class MobileMenu extends React.Component<MobileMenuProps,{}> {
  render() {
    const { classes: c, closeMenu, image, activePage, sections, hashLinks } = this.props;
    return (
      <div className={this.props.classes.mobileMenu}>
        <SimpleCollapse title="Why HQ">
          <SimpleCollapseItem title={'Ways to use HQ'} slug={'/use-cases'} />  
          <SimpleCollapseItem title={'Supported apps'} slug={'/apps'} />
        </SimpleCollapse>
        <SimpleCollapse title="Resources">
          <SimpleCollapseItem title={'Blog'} slug={'/blog'} />  
          <SimpleCollapseItem title={'Help Center'} externalSlug={'/hq.app/help'} />
        </SimpleCollapse>
        <SimpleCollapse title="Pricing" slug={'/pricing-plans'}></SimpleCollapse>       
        <div className={c.mobileMenuSocial}>
          <SocialBar dark />
        </div>
        <MdClear onClick={closeMenu} className={c.closeMenuIcon} />
        {
          image && <a href="http://startit.be/startups" className={c.startit}><Img  fixed={image} /></a>
        }
      </div>
    )
  }
}

export default injectSheet(styles)(MobileMenu);
